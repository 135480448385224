import UserService from '@services/UserService';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { classNames } from 'primereact/utils';
import { Dispatch, SetStateAction, useEffect, useState } from 'react';

import styles from './UserFilter.module.css';

type DropdownOption = {
  label: string;
  value: string;
  companyLabel: string;
};

type UserFilterProps = {
  value: string;
  setValue: Dispatch<SetStateAction<string>>;
};

const UserFilter = ({ value, setValue }: UserFilterProps): JSX.Element => {
  const userService = new UserService();

  const [dropdownOptions, setDropdownOptions] = useState<DropdownOption[]>([]);

  const { data: users } = userService.listUsers();

  useEffect(() => {
    if (users) {
      // Check if there exists duplicated usernames, if so, append the company name in paranthesis to the label
      const _userCountsMap = new Map<string, number>();

      users.forEach((user) => {
        const name = `${user.first_name}-${user.last_name}`;
        _userCountsMap.set(name, (_userCountsMap.get(name) ?? 0) + 1);
      });

      const _dropdownOptions: DropdownOption[] =
        users?.map((user) => {
          const name = user.first_name + '-' + user.last_name;
          const count = _userCountsMap.get(name) ?? 0;
          return {
            label: `${user.first_name} ${user.last_name}`,
            companyLabel:
              count > 1 && user.company?.company_name ? ` (${user.company?.company_name})` : '',
            value: user.id,
          };
        }) ?? [];

      setDropdownOptions(_dropdownOptions);
    }
  }, [users]);

  const onChange = (e: DropdownChangeEvent): void => {
    setValue(e.value);
  };

  const valueTemplate = (option: DropdownOption): JSX.Element => {
    if (option?.label) {
      return (
        <>
          <span className='text-primary'>User:</span> <span>{option?.label}</span>
        </>
      );
    }
    return <span>User</span>;
  };

  const itemTemplate = (option: DropdownOption): JSX.Element => (
    <span>
      {option.label}
      {option.companyLabel && <span className='text-gray-500'>{option.companyLabel}</span>}
    </span>
  );

  return (
    <div className={styles['dropdown']}>
      <Dropdown
        className={classNames(
          {
            'min-w-12rem max-w-20rem': value,
            'md:w-12rem': !value,
          },
          'w-full',
        )}
        options={dropdownOptions}
        value={value || null}
        placeholder='User'
        valueTemplate={valueTemplate}
        itemTemplate={itemTemplate}
        onChange={onChange}
        showClear
        filter
        pt={{
          input: {
            className: 'text-900',
          },
        }}
      />
    </div>
  );
};

export default UserFilter;
