import { IRequest } from '@shared/interfaces/IRequest';

import { ProductItem } from './SelectProductDropdown';

type MenuContentProps = {
  request: IRequest;
  filteredProducts: ProductItem[];
  onItemSelect: (item: ProductItem) => void;
};

type MenuProps = MenuContentProps & {
  onItemMouseDown: (e: React.MouseEvent<HTMLElement>) => void;
};

const MenuContent = ({
  request,
  filteredProducts,
  onItemSelect,
}: MenuContentProps): JSX.Element => {
  // If company id doesn't exist for the request (that means it is an external request), show text
  if (request.company.id === undefined) {
    return (
      <li className='p-dropdown-item cursor-auto'>
        <div className='flex w-full white-space-normal'>
          <span>Please import the company and the products for this company first</span>
        </div>
      </li>
    );
  }
  // Else, show products
  if (filteredProducts.length > 0) {
    return (
      <>
        {filteredProducts.map((product) => (
          <li
            key={product.productId}
            className='p-dropdown-item'
            onClick={(): void => onItemSelect(product)}
          >
            <div className='flex flex-column h-full w-full'>
              <div className='flex justify-content-between w-full'>
                <div className='w-full white-space-normal'>
                  <span>{product.productName}</span>
                </div>
                <span className='text-gray-500'>{product.productRegion}</span>
              </div>
              <span className='text-sm'>{product.productCid}</span>
            </div>
          </li>
        ))}
      </>
    );
  } else {
    return (
      <li className='p-dropdown-item cursor-auto'>
        <div className='flex w-full'>
          <span>No products found</span>
        </div>
      </li>
    );
  }
};

const Menu = ({
  request,
  filteredProducts,
  onItemMouseDown,
  onItemSelect,
}: MenuProps): JSX.Element => (
  <div className='relative' onMouseDown={onItemMouseDown}>
    <div className='p-dropdown-panel p-component p-ripple-disabled p-connected-overlay-enter-done z-2 absolute w-full max-h-15rem overflow-auto'>
      <ul className='p-dropdown-items'>
        <MenuContent
          request={request}
          filteredProducts={filteredProducts}
          onItemSelect={onItemSelect}
        />
      </ul>
    </div>
  </div>
);

export default Menu;
