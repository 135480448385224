import AmplifyConfig from '@config/AmplifyConfig';
import { GuestRouter, MainRouter } from '@config/router';
import { Amplify, Auth } from 'aws-amplify';
import { PrimeReactProvider } from 'primereact/api';

import AuthProvider, { AuthIsNotSignedIn, AuthIsSignedIn } from './contexts/auth/AuthProvider';
import NotificationProvider from './contexts/notification/NotificationProvider';
import WebsocketProvider from './contexts/websocket/WebsocketProvider';

Amplify.configure(AmplifyConfig);
Auth.configure({
  storage: window.localStorage.getItem('staySignedIn') === 'true' ? localStorage : sessionStorage,
});

function App(): JSX.Element {
  return (
    <PrimeReactProvider value={{ ripple: true }}>
      <NotificationProvider>
        <AuthProvider>
          {/* Router for authenticated user */}
          <AuthIsSignedIn>
            <WebsocketProvider>
              <MainRouter />
            </WebsocketProvider>
          </AuthIsSignedIn>

          {/* Router for unauthenticated user */}
          <AuthIsNotSignedIn>
            <GuestRouter />
          </AuthIsNotSignedIn>
        </AuthProvider>
      </NotificationProvider>
    </PrimeReactProvider>
  );
}

export default App;
