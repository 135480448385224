import { ToastSeverity } from '@shared/enums/ToastSeverity';

import useNotificationContext from './useNotificationContext';

type CopyToClipboardProps = {
  value?: string;
  callbackFn?: () => void;
};

const useCopyToClipboard = (): ((props: CopyToClipboardProps) => void) => {
  const { notify } = useNotificationContext();

  const copyToClipboard = ({ value, callbackFn }: CopyToClipboardProps): void => {
    navigator.clipboard.writeText(value ?? '');

    notify({
      severity: ToastSeverity.SUCCESS,
      detail: 'Copied to clipboard!',
      life: 3000,
    });

    if (callbackFn) {
      callbackFn();
    }
  };

  return copyToClipboard;
};

export default useCopyToClipboard;
