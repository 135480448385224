import { IAuthContext } from '@shared/interfaces/IAuthContext';
import { useContext } from 'react';

import AuthContext from '../contexts/auth/AuthContext';

const useAuthContext = (): IAuthContext => {
  const state: IAuthContext = useContext(AuthContext);

  if (state === undefined) {
    throw new Error('useAuthContext must be used within a AuthProvider');
  }

  return { ...state };
};

export default useAuthContext;
