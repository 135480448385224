import useCopyToClipboard from '@hooks/useCopyToClipboard';
import useNotificationContext from '@hooks/useNotificationContext';
import CompanyService from '@services/CompanyService';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { ICompany } from '@shared/interfaces/ICompany';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';
import { useState } from 'react';

import { ClientSecret } from '../client-secret/ClientSecret';

interface GenerateEDCConfigurationDialogProps extends DialogProps {
  company: ICompany;
}

function GenerateEDCConfigurationDialog(props: GenerateEDCConfigurationDialogProps): JSX.Element {
  const { company, onHide, ...restProps } = props;

  const [clientId, setClientId] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');

  const [isRevealSecret, setIsRevealSecret] = useState<boolean>(false);

  const queryClient = useQueryClient();
  const { notify } = useNotificationContext();
  const copyToClipboard = useCopyToClipboard();

  const companyService = new CompanyService();

  const onToggleRevealSecret = (): void => {
    setIsRevealSecret((prevState) => !prevState);
  };

  const { isLoading: isLoadingCreateCompanyAppClient, mutate: createCompanyAppClient } =
    companyService.createCompanyAppClient({
      onSuccess: (data) => {
        setClientId(data.ClientId);
        setClientSecret(data.ClientSecret);
      },
      onError: () => {
        notify({
          severity: ToastSeverity.ERROR,
          detail: 'Cannot create EDC Configuration. Please try again.',
          life: 3000,
        });

        onHideDialog();
      },
    });

  const {
    isLoading: isLoadingCompleteCompanyEDCConfigurationFirstStep,
    mutate: completeCompanyEDCConfigurationFirstStep,
  } = companyService.completeCompanyEDCConfigurationFirstStep({
    onSuccess: () => {
      onHideDialog();
      queryClient.invalidateQueries(['listCompanies']);
    },
    onError: () => {
      notify({
        severity: ToastSeverity.ERROR,
        detail: 'Something went wrong. Please try again.',
        life: 3000,
      });
    },
  });

  const onHideDialog = (): void => {
    setClientId('');
    setClientSecret('');
    onHide();
  };

  const onShow = (): void => {
    createCompanyAppClient({ companyId: company.id });
  };

  const onClickSave = (): void => {
    completeCompanyEDCConfigurationFirstStep({ companyId: company.id });
  };

  const footerContent = (): JSX.Element => (
    <div className='border-top-1 border-gray-300 p-3'>
      <Button
        onClick={onClickSave}
        disabled={isLoadingCompleteCompanyEDCConfigurationFirstStep}
        loading={isLoadingCompleteCompanyEDCConfigurationFirstStep}
      >
        I&apos;ve copied Client ID & Secret
      </Button>
    </div>
  );

  return (
    <Dialog
      {...restProps}
      onShow={onShow}
      onHide={onHideDialog}
      header={`${company?.company_name} EDC Configuration`}
      footer={footerContent}
      pt={{
        header: {
          className: 'bg-gray-200',
        },
        footer: {
          className: 'p-0',
        },
      }}
      draggable={false}
      blockScroll
    >
      <div className='flex bg-primary-100 border-round p-4 mb-4'>
        <i className='pi pi-info-circle mr-4 text-primary' />
        <div>
          <p>
            Please keep the Client Secret confidential. Do not share it with unauthorized
            individuals or via insecure channels. Exposure of this secret can lead to unauthorized
            access.
          </p>
        </div>
      </div>
      <p className='mb-2'>
        This Client ID and Client Secret were generated to authenticate the EDC to the PACIFIC
        backend.
      </p>
      <p className='mb-4'>
        Please ensure this information is shared exclusively with the customer&apos;s EDC provider.
      </p>
      <div className='flex flex-column gap-2 mb-4'>
        <span>Client ID</span>
        <div className='flex align-items-center justify-content-between bg-gray-100 p-3 border-1 border-gray-200 border-round'>
          <span className='w-full'>
            {isLoadingCreateCompanyAppClient ? <Skeleton className='w-15rem' /> : clientId}
          </span>
          <i
            className='pi pi-copy cursor-pointer'
            role='button'
            onClick={(): void => copyToClipboard({ value: clientId })}
          />
        </div>
      </div>
      <div className='flex flex-column gap-2'>
        <span>Client Secret</span>
        <div className='flex align-items-center justify-content-between gap-2 bg-gray-100 p-3 border-1 border-gray-200 border-round'>
          <ClientSecret
            isLoading={isLoadingCreateCompanyAppClient}
            clientSecret={clientSecret}
            isRevealSecret={isRevealSecret}
          />
          <div className='flex gap-3'>
            <i
              className={classNames('pi cursor-pointer', {
                'pi-eye': isRevealSecret,
                'pi-eye-slash': !isRevealSecret,
              })}
              role='button'
              onClick={onToggleRevealSecret}
            />
            <i
              className='pi pi-copy cursor-pointer'
              role='button'
              onClick={(): void => copyToClipboard({ value: clientSecret })}
            />
          </div>
        </div>
      </div>
    </Dialog>
  );
}

export default GenerateEDCConfigurationDialog;
