export enum RequestType {
  pcf = 'pcf',
  json_increase_limit = 'json_increase_limit',
  initial_product_list = 'initial_product_list',
  additional_product = 'additional_product',
  external_pcf = 'external_pcf',
}

export enum RequestSource {
  internal = 'internal',
  internal_auto = 'internal_auto',
  external = 'external',
}

export const mapRequestSourceToLabel = (source: RequestSource): string => {
  switch (source) {
    case RequestSource.internal:
      return 'Internal';
    case RequestSource.internal_auto:
      return 'Internal-Auto';
    case RequestSource.external:
      return 'External';
    default:
      return 'Unknown';
  }
};

export const mapRequestTypeToLabel = (type: RequestType): string => {
  switch (type) {
    case RequestType.pcf:
      return 'PCF';
    case RequestType.json_increase_limit:
      return 'Reset JSON Limit';
    case RequestType.initial_product_list:
      return 'Initial Product List';
    case RequestType.additional_product:
      return 'Additional Product';
    case RequestType.external_pcf:
      return 'External PCF';
    default:
      return 'Unknown';
  }
};
