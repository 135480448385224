import NotificationErrorContent from '@components/notification-error-content/NotificationErrorContent';
import SessionStorageService from '@services/SessionStorageService';
import {
  FileImportType,
  INotification,
  NotificationEventType,
} from '@shared/interfaces/INotification';
import { IToast } from '@shared/interfaces/IToast';

import { ToastSeverity } from '../enums/ToastSeverity';

type HandleNotificationProps = {
  notification: INotification;
  notify: ({ severity, summary, detail, life }: IToast) => void;
};

/**
 * Handles a notification and performs corresponding actions based on the notification type and success status.
 * Such as displaying a toast with the result of the event.
 * Or editing sessionStorage items
 *
 * @param notification - The notification object containing information about the event.
 * @param notify - The function used to display a toast notification.
 * @returns A promise that resolves when the notification handling is complete.
 */
const handleNotification = async ({
  notification,
  notify,
}: HandleNotificationProps): Promise<void> => {
  const sessionStorageService = new SessionStorageService();

  if (notification.eventType === NotificationEventType.ImportFile) {
    if (notification.success) {
      if (notification.type === FileImportType.IMPORT_COMPANIES) {
        sessionStorageService.deleteIsImportingCompanies();

        notify({
          severity: ToastSeverity.SUCCESS,
          summary: 'Success!',
          detail: 'Companies imported successfully!',
          life: 5000,
        });
      } else if (notification.type === FileImportType.IMPORT_PRODUCTS) {
        sessionStorageService.deleteIsImportingProducts();

        notify({
          severity: ToastSeverity.SUCCESS,
          summary: 'Success!',
          detail: 'Products imported successfully!',
          life: 5000,
        });
      } else if (notification.type === FileImportType.IMPORT_USERS) {
        sessionStorageService.deleteIsImportingUsers();

        notify({
          severity: ToastSeverity.SUCCESS,
          summary: 'Success!',
          detail: 'Users imported successfully!',
          life: 5000,
        });
      }
    } else {
      const detailErrorMessages = notification.detailErrorMessages;

      if (notification.type === FileImportType.IMPORT_COMPANIES) {
        sessionStorageService.deleteIsImportingCompanies();

        notify({
          severity: ToastSeverity.WARN,
          content: (
            <NotificationErrorContent
              title={'Failed to import some companies!'}
              detailErrorMessages={detailErrorMessages}
            />
          ),
        });
      } else if (notification.type === FileImportType.IMPORT_PRODUCTS) {
        sessionStorageService.deleteIsImportingProducts();

        notify({
          severity: ToastSeverity.WARN,
          content: (
            <NotificationErrorContent
              title={'Failed to import some products!'}
              detailErrorMessages={detailErrorMessages}
            />
          ),
        });
      } else if (notification.type === FileImportType.IMPORT_USERS) {
        sessionStorageService.deleteIsImportingUsers();

        notify({
          severity: ToastSeverity.WARN,
          summary: 'Failed to import some users!',
          detail: `There are ${notification.errorRows} incorrect rows in your file`,
        });
      }
    }
  } else {
    notify({
      severity: ToastSeverity.WARN,
      summary: 'Unknown notification type',
      detail: notification.message,
      life: 5000,
    });
  }
};

export default handleNotification;
