import ProductService from '@services/ProductService';
import { RequestRegionType } from '@shared/enums/RequestRegionType';
import { RequestType } from '@shared/enums/RequestType';
import { getRenderedTextLength } from '@shared/helpers/get-rendered-text-length';
import { IRequest } from '@shared/interfaces/IRequest';
import { InputText } from 'primereact/inputtext';
import { Skeleton } from 'primereact/skeleton';
import { classNames } from 'primereact/utils';
import { ChangeEvent, useEffect, useRef, useState } from 'react';

import Menu from './Menu';
import styles from './SelectProductDropdown.module.css';

interface SelectProductDropdownProps {
  request: IRequest;
  selectedProduct: ProductItem | undefined;
  setSelectedProduct: (product: ProductItem | undefined) => void;
}

export interface ProductItem {
  productName: string;
  productCid: string;
  productId: number;
  productRegion: string;
}

const SelectProductDropdown = (props: SelectProductDropdownProps): JSX.Element => {
  const { request, selectedProduct, setSelectedProduct } = props;

  const [filteredProducts, setFilteredProducts] = useState<ProductItem[]>([]);
  const [products, setProducts] = useState<ProductItem[]>([]);
  const [searchString, setSearchString] = useState<string>('');

  const [isMenuVisible, setIsMenuVisible] = useState<boolean>(false);

  const inputRef = useRef<HTMLInputElement>(null);

  const isRequestExternalOrAdditional =
    request.request_type === RequestType.external_pcf ||
    request.request_type === RequestType.additional_product;

  const productService = new ProductService();

  /* eslint-disable */
  const { isLoading, data: fetchedProducts } = productService.listProducts(
    {
      company_id: request?.company.id,
      hasRegion: true,
      product_cid: !isRequestExternalOrAdditional ? request.product?.product_id : undefined,
    },
    { enabled: request?.company?.id !== undefined }, // Fetch products only if the request has a company_id
  );
  /* eslint-enable */

  useEffect(() => {
    if (!fetchedProducts || isLoading) return;

    // Map products to ProductItem
    const _fetchedProducts = fetchedProducts
      .map((product) => ({
        productName: product?.product_name,
        productCid: product?.product_cid,
        productId: Number(product.product_id),
        productRegion:
          product.region_type === RequestRegionType.Global ? 'Global' : product?.region,
      }))
      .filter((product) => product.productId !== Number(request.product?.product_id));

    setProducts(_fetchedProducts);
    setFilteredProducts(_fetchedProducts);
  }, [fetchedProducts]);

  const onItemSelect = (item: ProductItem): void => {
    setSelectedProduct(item);
    setSearchString(item.productName);
    setIsMenuVisible(false);
  };

  const onSearchMouseDown = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
  };

  const onInputMouseDown = (): void => {
    inputRef.current?.focus();
    setIsMenuVisible(true);
  };

  const onRegionTextMouseDown = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
  };

  const onInputBlur = (): void => {
    setIsMenuVisible(false);
  };

  const onTriggerMouseDown = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    setIsMenuVisible(!isMenuVisible);
  };

  const onInputChange = (e: ChangeEvent<HTMLInputElement>): void => {
    const { value } = e.target;

    if (!isMenuVisible) setIsMenuVisible(true);

    setSearchString(value);

    if (value !== selectedProduct?.productName) {
      setSelectedProduct(undefined);
    }

    setFilteredProducts(
      products.filter((product) => product.productName.toLowerCase().includes(value.toLowerCase())),
    );
  };

  const onItemMouseDown = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();
  };

  if (isLoading && request.company.id !== undefined) {
    return <Skeleton className='w-full' height='45px' />;
  }

  return (
    <div className='field p-fluid' onBlur={onInputBlur}>
      <div
        className={classNames(
          'flex flex-row align-items-center p-float-label',
          styles['input-wrapper'],
        )}
        onMouseDown={onInputMouseDown}
      >
        <InputText
          ref={inputRef}
          name='product'
          type='text'
          onChange={onInputChange}
          value={searchString ?? undefined}
          className='line-height-1 text-overflow-ellipsis w-full'
          style={{ textIndent: '2rem' }}
        />
        <label htmlFor='product'>Select Product</label>

        {/* Search Icon */}
        <div
          className='flex align-items-center px-2 absolute left-0 mx-3 text-gray-400 z-4'
          onMouseDown={onSearchMouseDown}
        >
          <i className='pi pi-search' />
        </div>

        {/* Trigger Icon */}
        <div
          className='flex align-items-center  absolute right-0 px-2 py-2 mr-3 text-gray-400 z-4'
          onMouseDown={onTriggerMouseDown}
        >
          <i className={classNames('pi', isMenuVisible ? 'pi-angle-up' : 'pi-angle-down')} />
        </div>

        {/* Region text */}
        {selectedProduct?.productRegion && (
          <div
            className='text-gray-500 absolute flex align-items-center line-height-1 pl-1'
            style={{ left: getRenderedTextLength(selectedProduct.productName) }}
            onMouseDown={onRegionTextMouseDown}
          >
            ({selectedProduct?.productRegion})
          </div>
        )}
      </div>
      {/* Dropdown Menu */}
      {isMenuVisible && (
        <Menu
          request={request}
          filteredProducts={filteredProducts}
          onItemMouseDown={onItemMouseDown}
          onItemSelect={onItemSelect}
        />
      )}
    </div>
  );
};

export default SelectProductDropdown;
