import ImportCompanies from '@pages/main/companies/components/import-companies/ImportCompanies';
import CompanyService from '@services/CompanyService';
import { Button } from 'primereact/button';
import { Dialog, DialogPassThroughType } from 'primereact/dialog';
import { HTMLAttributes, useEffect, useState } from 'react';

import useAuthContext from '../../../../hooks/useAuthContext';

const GetStartedDialog = (): JSX.Element | null => {
  const { signOut } = useAuthContext();

  const companyService = new CompanyService();
  const [visible, setVisible] = useState<boolean>(true);
  const [isSigningOut, setIsSigningOut] = useState<boolean>(false);

  // RQ Query to get companies
  const { isLoading, data: companies } = companyService.listCompanies();

  const onHide = (): void => {
    setVisible(false);
  };

  const onSignOut = async (): Promise<void> => {
    setIsSigningOut(true);

    await signOut?.();

    setIsSigningOut(false);
  };

  useEffect(() => {
    window.addEventListener('finishedImportingCompanies', (): void => {
      setVisible(false);
    });
  }, []);

  if (isLoading) return null;

  if (!companies || companies.length > 0 || isLoading) return null;
  else {
    return (
      <Dialog
        header='Get Started'
        visible={visible}
        onHide={onHide}
        closeIcon={false}
        closable={false}
        draggable={false}
        pt={{
          root: {
            'data-cy': 'welcome-dialog',
          } as DialogPassThroughType<HTMLAttributes<HTMLDivElement>>,
        }}
      >
        <div className='pb-4'>
          <h1>Welcome to Pacific Admin Panel</h1>
          <p>Please import an Excel file with a list of companies to get started.</p>
          <p className='flex flex-row'>
            Please Take a look at our
            <a
              href={
                process.env.REACT_APP_S3_CONTENT_URL +
                '/sample-uploads/Pacific+Sample+Companies.xlsx'
              }
              download='Pacific Sample Companies.xlsx'
              target='_blank'
              rel='noreferrer'
              className='text-primary mx-1'
            >
              sample
            </a>
            Excel file
          </p>
        </div>

        <div className='flex flex-row justify-content-between'>
          <ImportCompanies showText={false} />
          <Button
            text
            icon='pi pi-sign-out'
            label='Logout'
            onClick={onSignOut}
            loading={isSigningOut}
            data-cy='sign-out-button'
          />
        </div>
      </Dialog>
    );
  }
};

export default GetStartedDialog;
