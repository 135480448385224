import { ICompany } from '@shared/interfaces/ICompany';
import { Button } from 'primereact/button';

type GenerateEDCConfigurationButtonProps = {
  data: ICompany;
  onClick?: () => void;
};

function GenerateEDCConfigurationButton(
  props: GenerateEDCConfigurationButtonProps,
): JSX.Element | null {
  if (!props?.data?.bpn) {
    return null;
  }

  return (
    <Button outlined onClick={props.onClick}>
      Generate EDC Configuration
    </Button>
  );
}

export default GenerateEDCConfigurationButton;
