import useNotificationContext from '@hooks/useNotificationContext';
import CompanyService from '@services/CompanyService';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { Button, ButtonProps } from 'primereact/button';
import { useEffect, useState } from 'react';

interface TestEdcConnectionButtonProps extends ButtonProps {
  companyId: string;
  edcBaseUrl: string;
  edcClientId: string;
  edcClientSecret: string;
}

const TestEdcConnectionButton = ({
  companyId,
  edcBaseUrl,
  edcClientId,
  edcClientSecret,
  ...buttonProps
}: TestEdcConnectionButtonProps): JSX.Element => {
  const [isTestSuccessful, setIsTestSuccessful] = useState(false);
  const [testedFormFields, setTestedFormFields] = useState<
    Omit<TestEdcConnectionButtonProps, 'companyId'>
  >({
    edcBaseUrl: '',
    edcClientId: '',
    edcClientSecret: '',
  });

  const { notify } = useNotificationContext();

  const companyService = new CompanyService();

  const { isLoading, mutate: testEdcConnection } = companyService.testCompanyEDCConfiguration({
    onSuccess: () => {
      setIsTestSuccessful(true);

      setTestedFormFields({
        edcBaseUrl,
        edcClientId,
        edcClientSecret,
      });
    },
    onError: () => {
      setIsTestSuccessful(false);
      notify({
        severity: ToastSeverity.ERROR,
        summary: 'Test connection failed',
        detail: 'Please try again later.',
        life: 5000,
      });
    },
  });

  useEffect(() => {
    if (
      testedFormFields.edcBaseUrl !== edcBaseUrl ||
      testedFormFields.edcClientId !== edcClientId ||
      testedFormFields.edcClientSecret !== edcClientSecret
    ) {
      setIsTestSuccessful(false);
    }
  }, [edcBaseUrl, edcClientId, edcClientSecret]);

  const onClick = (e: React.MouseEvent<HTMLElement>): void => {
    e.preventDefault();

    testEdcConnection({
      companyId,
      edcBaseUrl,
      edcClientId,
      edcClientSecret,
    });
  };

  return (
    <div className='flex align-items-center'>
      <Button
        className='underline p-0'
        text
        label='Test connection'
        onClick={onClick}
        disabled={isLoading}
        loading={isLoading}
        pt={{
          loadingIcon: { className: 'p-button-icon-right ml-2' },
          label: { className: 'ml-0' },
        }}
        {...buttonProps}
      />
      {!isLoading && isTestSuccessful && <i className='pi pi-check-circle ml-2 text-primary' />}
    </div>
  );
};

export default TestEdcConnectionButton;
