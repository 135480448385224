import ExportProducts from './components/export-products/ExportProducts';
import ImportProducts from './components/import-products/ImportProducts';
import ProductsTable from './components/products-table/ProductsTable';

const Products = (): JSX.Element => (
  <div className='flex flex-column gap-1 justify-content-start align-items-start w-full'>
    <h1 className='text-2xl md:text-4xl'>Products</h1>

    <div className='flex flex-column gap-2 align-items-start mb-2 w-full'>
      <ImportProducts />
      <ExportProducts />
    </div>
    <ProductsTable />
  </div>
);

export default Products;
