import useNotificationContext from '@hooks/useNotificationContext';
import RequestService from '@services/RequestService';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { IRequest } from '@shared/interfaces/IRequest';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { useState } from 'react';

import SelectProductDropdown, {
  ProductItem,
} from '../select-product-dropdown/SelectProductDropdown';

interface AnswerPcfDialogProps extends DialogProps {
  request: IRequest;
}

const AnswerPcfDialog = (props: AnswerPcfDialogProps): JSX.Element => {
  const { request, onHide, visible, ...restProps } = props;
  const { notify } = useNotificationContext();

  const [selectedProduct, setSelectedProduct] = useState<ProductItem | undefined>(undefined);

  const requestService = new RequestService();
  const queryClient = useQueryClient();

  const { isLoading, mutate: sharePcf } = requestService.answerPcfRequest({
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['listRequests'] });

      notify({
        severity: ToastSeverity.SUCCESS,
        detail: 'Answered PCF request successfully!',
        life: 5000,
      });

      onHideDialog();
    },
    onError: () => {
      notify({
        severity: ToastSeverity.ERROR,
        detail: 'Failed to answer PCF request! Please try again.',
        life: 5000,
      });
    },
  });

  const onHideDialog = (): void => {
    setSelectedProduct(undefined);
    onHide();
  };

  const onSharePcfClick = (): void => {
    sharePcf({
      requestId: request.id,
      selectedProductId: selectedProduct?.productId ?? 0,
      requestType: request.request_type,
    });
  };

  const footerContent = (): JSX.Element => (
    <div className='border-top-1 border-gray-300 p-3'>
      <Button text className='text-gray-500' onClick={onHideDialog} label='Cancel' />
      <Button
        disabled={!selectedProduct || isLoading}
        loading={isLoading}
        onClick={onSharePcfClick}
        label='Share PCF'
      />
    </div>
  );

  return (
    <Dialog
      header='Answer PCF Request'
      pt={{
        header: {
          className: 'bg-gray-200',
        },
        footer: {
          className: 'p-0',
        },
        content: { className: 'overflow-visible' },
      }}
      footer={footerContent}
      visible={visible}
      onHide={onHideDialog}
      draggable={false}
      className='w-4 min-w-30rem'
      blockScroll
      {...restProps}
    >
      <div className='mb-4 overflow-visible'>
        <p>
          Please select the Product and Region for which you would like to share the PCF for this
          request from {request.company?.company_name}
        </p>

        <SelectProductDropdown
          request={request}
          selectedProduct={selectedProduct}
          setSelectedProduct={setSelectedProduct}
        />
      </div>
    </Dialog>
  );
};

export default AnswerPcfDialog;
