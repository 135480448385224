import { RouterPath } from '@config/router';
import { OverlayPanel } from 'primereact/overlaypanel';
import { classNames } from 'primereact/utils';
import { MouseEventHandler, useRef, useState } from 'react';

import Logo, { LogoType } from '../logo/Logo';
import GetStartedDialog from './components/get-started-dialog/GetStartedDialog';
import HeaderLink from './components/header-link/HeaderLink';
import MenuButton from './components/menu-button/MenuButton';
import MobileMenuOverlay from './components/mobile-menu-overlay/MobileMenuOverlay';
import UserButton from './components/user-button/UserButton';
import styles from './Header.module.css';

const Header = (): JSX.Element => {
  const overlayPanelRef = useRef<OverlayPanel>(null);
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const onMenuClick: MouseEventHandler<HTMLButtonElement> = (event): void => {
    if (overlayPanelRef.current) {
      overlayPanelRef.current?.toggle(event);
    }
  };

  const onOverlayPanelShow = (): void => {
    setIsOverlayVisible(true);
  };

  const onOverlayPanelHide = (): void => {
    setIsOverlayVisible(false);
  };

  return (
    <>
      <GetStartedDialog />
      <div
        className={classNames(
          'flex justify-content-between align-items-center h-4rem px-4 sm:px-6',
          styles.header,
        )}
      >
        <Logo className={styles['logo']} type={LogoType.COMBINED} white />

        <div className={classNames('h-full ', styles['desktop-header-links'])}>
          <HeaderLink path={RouterPath.COMPANIES} label='Companies' />
          <HeaderLink path={RouterPath.USERS} label='Users' />
          <HeaderLink path={RouterPath.PRODUCTS} label='Products' />
          <HeaderLink path={RouterPath.REQUESTS} label='Requests' />
        </div>

        <div className={styles['user-info']}>
          <UserButton />
        </div>

        <div className={classNames(styles['mobile-content'], 'gap-3')}>
          <MenuButton isOpen={isOverlayVisible} onClick={onMenuClick} />
          <UserButton />
        </div>

        {/* Panel opens when clicked on 'menu' button (small screens) */}
        <OverlayPanel
          ref={overlayPanelRef}
          className='w-full shadow-2 mt-2'
          pt={{
            content: {
              className: 'flex flex-column align-items-center',
            },
          }}
          onShow={onOverlayPanelShow}
          onHide={onOverlayPanelHide}
        >
          <MobileMenuOverlay />
        </OverlayPanel>
      </div>
    </>
  );
};

export default Header;
