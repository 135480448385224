import useNotificationContext from '@hooks/useNotificationContext';
import CompanyService from '@services/CompanyService';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';

import { RemoveButtonAction } from '../remove-button/RemoveButton';

interface RemoveProductCompanyDialogProps extends DialogProps {
  action: RemoveButtonAction;
  productId: string;
  companyId: string;
}

const RemoveProductCompanyDialog = (props: RemoveProductCompanyDialogProps): JSX.Element => {
  const { action, productId, companyId, onHide, visible, ...restProps } = props;

  const companyService = new CompanyService();
  const { notify } = useNotificationContext();
  const queryClient = useQueryClient();

  const { isLoading, mutate: removeProductCompany } = companyService.removeProductCompany({
    onSuccess: () => {
      // Refresh companyProducts table
      queryClient.invalidateQueries({ queryKey: ['listCompaniesByProductId', productId] });

      // Refresh products table
      queryClient.invalidateQueries({ queryKey: ['listProducts'] });

      // Refresh requests table
      queryClient.invalidateQueries({ queryKey: ['listRequests'] });

      notify({
        severity: ToastSeverity.SUCCESS,
        detail: 'Assignment removed successfully',
        life: 5000,
      });
    },
    onError: () => {
      notify({
        severity: ToastSeverity.ERROR,
        detail: 'Assignment removal failed',
        life: 5000,
      });
    },
    onSettled: () => {
      // Close dialog in all cases
      onHide();
    },
  });

  const getDialogTitle = (): string => {
    switch (action) {
      case RemoveButtonAction.REMOVE:
        return 'Confirm Product Assignment Deletion';
      case RemoveButtonAction.DECLINE:
        return 'Confirm PCF Decline';
      case RemoveButtonAction.EXPIRE:
        return 'Confirm Withdraw of Product Access';
      default:
        return '';
    }
  };

  const getDialogText = (): string => {
    switch (action) {
      case RemoveButtonAction.REMOVE:
        return 'Deleting this product assignment will remove it from the customer\'s list of products. Are you sure you want to proceed with deletion?'; // prettier-ignore
      case RemoveButtonAction.DECLINE:
        return 'The status for the customer will be set to “Declined”. Are you sure you want to proceed with declining the PCF request?'; // prettier-ignore
      case RemoveButtonAction.EXPIRE:
        return 'You have already shared the PCF for this product. Withdrawing this product will set its status to "Expired" and will prevent the customer from accessing the associated detailed PCF information. Are you sure you want to proceed with withdrawing access?'; // prettier-ignore
      default:
        return '';
    }
  };

  const getDialogButtonText = (): string => {
    switch (action) {
      case RemoveButtonAction.REMOVE:
        return 'Delete';
      case RemoveButtonAction.DECLINE:
        return 'Decline';
      case RemoveButtonAction.EXPIRE:
        return 'Withdraw';
      default:
        return '';
    }
  };

  const footerContent = (): JSX.Element => (
    <div className='border-top-1 border-gray-300 p-3'>
      <Button text className='text-gray-500' onClick={onHide}>
        Cancel
      </Button>
      <Button
        label={getDialogButtonText()}
        onClick={(): void => {
          removeProductCompany({ companyId, productId });
        }}
        loading={isLoading}
        disabled={isLoading}
      />
    </div>
  );

  return (
    <Dialog
      header={getDialogTitle()}
      pt={{
        header: {
          className: 'bg-gray-200',
        },
        footer: {
          className: 'p-0',
        },
      }}
      footer={footerContent}
      visible={visible}
      onHide={onHide}
      className='w-full sm:w-8 md:w-4'
      draggable={false}
      {...restProps}
    >
      <div className='mb-4'>
        <p>{getDialogText()}</p>
      </div>
    </Dialog>
  );
};

export default RemoveProductCompanyDialog;
