import { RequestType } from '@shared/enums/RequestType';
import { removeUndefinedFields } from '@shared/helpers/remove-undefined-fields';
import IAmplifyApiConfig from '@shared/interfaces/IAmplifyApiConfig';
import { IRequest } from '@shared/interfaces/IRequest';
import {
  useMutation,
  UseMutationOptions,
  UseMutationResult,
  useQuery,
  UseQueryResult,
} from '@tanstack/react-query';

import AmplifyApiService from './AmplifyApiService';
import ImportFileService from './ImportFileService';

export type RequestFilter = {
  company_id?: string;
  request_source?: string;
  requested_by?: string;
  product_id?: string;
};

type UploadRequestsProps = {
  file: File;
};

type answerPcfRequestProps = {
  requestId: number;
  selectedProductId: number;
  requestType: RequestType;
};

type RQMutationSideEffectProps = {
  onMutate?: () => void;
  onSuccess?: () => void;
  onError?: () => void;
};

class RequestService {
  private amplifyApiService: AmplifyApiService;
  private importFileService: ImportFileService;

  constructor() {
    this.amplifyApiService = new AmplifyApiService();
    this.importFileService = new ImportFileService();
  }

  listRequests(filter: RequestFilter = {}): UseQueryResult<IRequest[], Error> {
    // Clear undefined values from filter
    const _filter = removeUndefinedFields(filter);

    const amplifyApiConfig: IAmplifyApiConfig = {
      apiName: 'PacificApi',
      path: '/request',
      init: {
        queryStringParameters: { ..._filter },
      },
    };

    const query = useQuery<IRequest[], Error>({
      queryKey: ['listRequests', _filter],
      queryFn: async (): Promise<IRequest[]> => {
        const response = await this.amplifyApiService.get<{ requests: IRequest[] }>({
          config: amplifyApiConfig,
        });

        return response.requests;
      },
    });

    return query;
  }

  importRequests({
    onMutate,
    onSuccess,
    onError,
  }: RQMutationSideEffectProps): UseMutationResult<void, Error, UploadRequestsProps> {
    const mutation = useMutation<void, Error, UploadRequestsProps>({
      mutationFn: async ({ file }: UploadRequestsProps) => {
        const filename = file.name;

        const signedUrl = await this.importFileService.getSignedUrl({
          filename,
          type: 'import_requests',
        });

        await this.importFileService.uploadFile({
          file,
          signedUrl,
        });
      },
      onMutate,
      onSuccess,
      onError,
    });

    return mutation;
  }

  answerPcfRequest(
    options: UseMutationOptions<void, Error, answerPcfRequestProps>,
  ): UseMutationResult<void, Error, answerPcfRequestProps> {
    return useMutation<void, Error, answerPcfRequestProps>({
      mutationFn: async ({ requestId, selectedProductId, requestType }: answerPcfRequestProps) => {
        // Determine action: answer external or internal request, based on request type
        let action;
        if (requestType === RequestType.external_pcf) action = 'answer-external-request';
        else action = 'answer-internal-new-non-regional-request';

        const amplifyApiConfig: IAmplifyApiConfig = {
          apiName: 'PacificApi',
          path: `/request/${requestId}?action=${action}`,
          init: {},
        };

        const response = await this.amplifyApiService.put<{ productId: number }, void>({
          config: amplifyApiConfig,
          body: {
            productId: selectedProductId,
          },
        });

        return response;
      },
      ...options,
    });
  }
}

export default RequestService;
