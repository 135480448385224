import FailedToGet from '@components/failed-to-get/FailedToGet';
import CompanyService from '@services/CompanyService';
import { EdcConfigurationStatus, ICompany, LicenseType } from '@shared/interfaces/ICompany';
import { Column } from 'primereact/column';
import { DataTable } from 'primereact/datatable';
import { useState } from 'react';

import ChangeLicenseButton from './components/change-lincense/ChangeLicenseButton';
import ChangeLicenseDialog from './components/change-lincense/ChangeLicenseDialog';
import CompaniesTableHeader from './components/companies-table-header/CompaniesTableHeader';
import ConfigureEDCConnectionButton from './components/configure-edc-connection/ConfigureEDCConnectionButton';
import ConfigureEDCConnectionDialog from './components/configure-edc-connection/ConfigureEDCConnectionDialog';
import GenerateEDCConfigurationButton from './components/generate-edc-configuration/GenerateEDCConfigurationButton';
import GenerateEDCConfigurationDialog from './components/generate-edc-configuration/GenerateEDCConfigurationDialog';

/* eslint-disable camelcase */
const emptyCompany: ICompany = {
  id: '',
  company_id: '',
  company_name: '',
  license: LicenseType.BASIC,
};
/* eslint-enable camelcase */

const CompaniesTable = (): JSX.Element => {
  const [edcConfigurationCompany, setEdcConfigurationCompany] = useState<ICompany>(emptyCompany);
  const [connectionConfigurationCompany, setConnectionConfigurationCompany] =
    useState<ICompany>(emptyCompany);
  const [licenseCompany, setLicenseCompany] = useState<ICompany>(emptyCompany);

  const companyService = new CompanyService();

  // Dialog visibility handlers (show)
  const onGenerateEDCConfigurationButtonClick = (company: ICompany): void =>
    setEdcConfigurationCompany(company);

  const onConfigureConnectionConfigurationButtonClick = (company: ICompany): void =>
    setConnectionConfigurationCompany(company);

  const onLicenseButtonClick = (company: ICompany): void => setLicenseCompany(company);

  // Dialog visibility handlers (hide)
  const onGenerateEDCConfigurationDialogHide = (): void => {
    setEdcConfigurationCompany(emptyCompany);
  };
  const onConfigureConnectionConfigurationDialogHide = (): void => {
    setConnectionConfigurationCompany(emptyCompany);
  };
  const onChangeLicenseDialogHide = (): void => {
    setLicenseCompany(emptyCompany);
  };

  const edcConfigurationTemplate = (data: ICompany): JSX.Element => {
    if (data.edc_configuration_status !== EdcConfigurationStatus.Pending) {
      return (
        <ConfigureEDCConnectionButton
          data={data}
          onClick={(): void => onConfigureConnectionConfigurationButtonClick(data)}
        />
      );
    } else {
      return (
        <GenerateEDCConfigurationButton
          data={data}
          onClick={(): void => onGenerateEDCConfigurationButtonClick(data)}
        />
      );
    }
  };

  const licenseTemplate = (data: ICompany): JSX.Element => (
    <ChangeLicenseButton data={data} onClick={(): void => onLicenseButtonClick(data)} />
  );

  // RQ Query to get companies
  const {
    isLoading: isGetCompaniesLoading,
    isFetching: isGetCompaniesFetching,
    isError: isGetCompaniesError,
    error: getCompaniesError,
    data: companies,
    refetch: refetchCompanies,
  } = companyService.listCompanies();

  if (isGetCompaniesError) {
    return <FailedToGet label='companies' reason={getCompaniesError.message} />;
  }

  return (
    <div className='w-full'>
      <DataTable
        value={companies}
        sortField='company_id'
        sortOrder={1}
        header={
          <CompaniesTableHeader refetch={refetchCompanies} isFetching={isGetCompaniesFetching} />
        }
        loading={isGetCompaniesLoading}
      >
        <Column field='bpn' sortable header='BPN' />
        <Column field='company_id' sortable header='Company ID' className='w-2' />
        <Column field='company_name' sortable header='Company Name' />
        <Column field='license' sortable header='License' body={licenseTemplate} className='w-2' />
        <Column align='right' body={edcConfigurationTemplate} />
      </DataTable>

      <GenerateEDCConfigurationDialog
        style={{ maxWidth: '600px' }}
        company={edcConfigurationCompany}
        visible={Boolean(edcConfigurationCompany?.id)}
        onHide={onGenerateEDCConfigurationDialogHide}
      />
      <ConfigureEDCConnectionDialog
        style={{ maxWidth: '600px' }}
        company={connectionConfigurationCompany}
        visible={Boolean(connectionConfigurationCompany?.id)}
        onHide={onConfigureConnectionConfigurationDialogHide}
      />
      <ChangeLicenseDialog
        style={{ width: '600px', height: '500px' }}
        company={licenseCompany}
        visible={Boolean(licenseCompany?.id)}
        onHide={onChangeLicenseDialogHide}
      />
    </div>
  );
};

export default CompaniesTable;
