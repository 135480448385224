import CustomIcon from '@components/custom-icon/CustomIcon';
import { CommentIcon } from '@components/custom-icon/icons/CommentIcon';
import { IRequest } from '@shared/interfaces/IRequest';
import { useState } from 'react';

import CommentDialog from '../comment-dialog/CommentDialog';

interface CommentIconButtonProps {
  request: IRequest;
}

const CommentIconButton = ({ request }: CommentIconButtonProps): JSX.Element => {
  const [isCommentDialogVisible, setIsCommentDialogVisible] = useState<boolean>(false);
  return (
    <div>
      <CustomIcon
        icon={CommentIcon}
        className='cursor-pointer flex justify-content-center align-items-center'
        hoverColor='var(--primary-color)'
        onClick={(): void => setIsCommentDialogVisible(true)}
      />

      <CommentDialog
        comment={request.request_description ?? ''}
        visible={isCommentDialogVisible}
        onHide={(): void => setIsCommentDialogVisible(false)}
      />
    </div>
  );
};

export default CommentIconButton;
