import { RequestStatus } from '@shared/enums/RequestStatus';

export const getRequestStatusColors = (
  status: RequestStatus,
): { backgroundColor: string; color: string } => {
  let backgroundColor = '';
  let color = '';

  switch (status) {
    case RequestStatus.complete:
      backgroundColor = 'var(--request-status-green-background-color)';
      color = 'var(--request-status-green-text-color)';
      break;
    case RequestStatus.pending:
      backgroundColor = 'var(--request-status-orange-background-color)';
      color = 'var(--request-status-orange-text-color)';
      break;
    case RequestStatus.rejected:
    case RequestStatus.outdated:
      backgroundColor = 'var(--request-status-red-background-color)';
      color = 'var(--request-status-red-text-color)';
      break;
  }

  return { backgroundColor, color };
};
