import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';

interface CommentDialogProps extends DialogProps {
  comment: string;
}

const CommentDialog = (props: CommentDialogProps): JSX.Element => {
  const { comment, onHide, visible, ...restProps } = props;

  const footerContent = (): JSX.Element => (
    <div className='border-top-1 border-gray-300 p-3'>
      <Button onClick={onHide}>Close</Button>
    </div>
  );
  return (
    <Dialog
      header='Comment'
      pt={{
        header: {
          className: 'bg-gray-200',
        },
        footer: {
          className: 'p-0',
        },
      }}
      footer={footerContent}
      visible={visible}
      onHide={onHide}
      className='w-full sm:w-8 md:w-4'
      draggable={false}
      {...restProps}
    >
      <div className='mb-4'>
        <p>{comment}</p>
      </div>
    </Dialog>
  );
};

export default CommentDialog;
