import { IRequest } from '@shared/interfaces/IRequest';
import { Button, ButtonProps } from 'primereact/button';
import { useState } from 'react';

import AnswerPcfDialog from '../answer-pcf-dialog/AnswerPcfDialog';

interface AnswerButtonProps extends ButtonProps {
  request: IRequest;
}

const AnswerButton = (props: AnswerButtonProps): JSX.Element => {
  const { request, ...restProps } = props;

  const [isAnswerPcfDialogVisible, setIsAnswerPcfDialogVisible] = useState<boolean>(false);

  const onClick = (): void => setIsAnswerPcfDialogVisible(true);

  return (
    <div>
      <Button {...restProps} onClick={onClick}>
        Answer
      </Button>

      <AnswerPcfDialog
        visible={isAnswerPcfDialogVisible}
        onHide={(): void => setIsAnswerPcfDialogVisible(false)}
        request={request}
      />
    </div>
  );
};

export default AnswerButton;
