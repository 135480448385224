// Calculate width of text in pixels, based on the class name ".p-inputtext"
export const getRenderedTextLength = (text: string): number => {
  const span = document.createElement('span');
  span.className = 'p-inputtext p-component p-filled pr-0 mr-0';
  span.textContent = text;
  document.body.appendChild(span);
  const width = span.offsetWidth;
  document.body.removeChild(span);
  return width + 32; // 32 is the width of the input text indent, which is 2rem
};
