import { ICompany, mapLicenseTypeToLabel } from '@shared/interfaces/ICompany';
import { Button } from 'primereact/button';

type ChangeLicenseButtonProps = {
  data: ICompany;
  onClick: () => void;
};

function ChangeLicenseButton(props: ChangeLicenseButtonProps): JSX.Element | null {
  const { data, onClick } = props;

  if (!data.license) {
    return null;
  }

  return (
    <Button text className='underline' onClick={onClick}>
      {mapLicenseTypeToLabel(data.license)}
    </Button>
  );
}

export default ChangeLicenseButton;
