import { MASKED_SECRET } from '@shared/constants';
import { Skeleton } from 'primereact/skeleton';

type ClientSecretProps = {
  isLoading: boolean;
  clientSecret: string;
  isRevealSecret: boolean;
};

export const ClientSecret = ({
  isLoading,
  clientSecret,
  isRevealSecret,
}: ClientSecretProps): JSX.Element | null => {
  //  style wordBreak is needed as it's not supported by primeflex
  if (isLoading) {
    return <Skeleton className='w-15rem' />;
  }

  if (!clientSecret) return null;

  return (
    <span style={{ wordBreak: 'break-word' }}>{isRevealSecret ? clientSecret : MASKED_SECRET}</span>
  );
};
