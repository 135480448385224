import CustomInputText from '@components/custom-input-text/CustomInputText';
import useCopyToClipboard from '@hooks/useCopyToClipboard';
import { useForm } from '@hooks/useForm';
import useNotificationContext from '@hooks/useNotificationContext';
import CompanyService from '@services/CompanyService';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { ICompany } from '@shared/interfaces/ICompany';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Skeleton } from 'primereact/skeleton';
import { Tooltip } from 'primereact/tooltip';
import { classNames } from 'primereact/utils';
import { useEffect, useState } from 'react';
import * as yup from 'yup';

import { ClientSecret } from '../client-secret/ClientSecret';
import TestEdcConnectionButton from '../test-edc-connection/TestEdcConnectionButton';

interface ConfigureEDCConnectionDialogProps extends DialogProps {
  company: ICompany;
}

interface EdcConfigurationFormProps {
  edcBaseUrl: string;
  edcClientId: string;
  edcClientSecret: string;
  pcfExchangeClientSecretKey?: string;
}

const EdcConfigurationSchema = yup
  .object()
  .shape({
    edcBaseUrl: yup.string().required('EDC URL is required'),
    edcClientId: yup.string().required('Username is required'),
    edcClientSecret: yup.string().required('Password is required'),
    pcfExchangeClientSecretKey: yup.string().optional(),
  })
  .required();

function ConfigureEDCConnectionDialog(props: ConfigureEDCConnectionDialogProps): JSX.Element {
  const { company, onHide, ...restProps } = props;

  const [clientId, setClientId] = useState<string>('');
  const [clientSecret, setClientSecret] = useState<string>('');

  const [isRevealSecret, setIsRevealSecret] = useState<boolean>(false);
  const [isRevealPassword, setIsRevealPassword] = useState<boolean>(false);
  const [isRevealClientSecretKey, setIsRevealClientSecretKey] = useState<boolean>(false);

  const { notify } = useNotificationContext();
  const copyToClipboard = useCopyToClipboard();

  const queryClient = useQueryClient();
  const companyService = new CompanyService();

  const { isLoading: isLoadingFetchCompanyEDCConfiguration, data: fetchedCompanyEdcConfiguration } =
    companyService.fetchCompanyEDCConfiguration(company.id, {
      enabled: Boolean(company.id),
    });

  const { register, handleSubmit, reset, getValues, errors, watch } =
    useForm<EdcConfigurationFormProps>({
      schema: EdcConfigurationSchema,
      defaultValues: {
        edcBaseUrl: '',
        edcClientId: '',
        edcClientSecret: '',
        pcfExchangeClientSecretKey: '',
      },
    });

  // Watching the fields to be used in the TestEdcConnectionButton component
  // We want to test the connection with the values entered in the form, rather then the values fetched from the API
  const watchFields = watch(['edcBaseUrl', 'edcClientId', 'edcClientSecret']);

  const onSubmit = async (data: EdcConfigurationFormProps): Promise<void> => {
    const { edcBaseUrl, edcClientId, edcClientSecret, pcfExchangeClientSecretKey } = data;

    saveCompanyEDCConfiguration({
      companyId: company.id,
      edcBaseUrl,
      edcClientId,
      edcClientSecret,
      pcfExchangeClientSecretKey,
    });
  };

  useEffect(() => {
    reset({
      edcBaseUrl: company.edc_base_url ?? '',
    });
  }, [company]);

  useEffect(() => {
    if (fetchedCompanyEdcConfiguration) {
      // Set the client ID and secret to be displayed
      setClientId(fetchedCompanyEdcConfiguration.cognito_client_id);
      setClientSecret(fetchedCompanyEdcConfiguration.cognito_client_secret);

      // Fill form fields with the fetched data
      reset({
        edcBaseUrl: fetchedCompanyEdcConfiguration.edc_base_url,
        edcClientId: fetchedCompanyEdcConfiguration.edc_client_id,
        edcClientSecret: fetchedCompanyEdcConfiguration.edc_client_secret,
        pcfExchangeClientSecretKey: fetchedCompanyEdcConfiguration.pcf_exchange_client_secret_key,
      });
    }
  }, [fetchedCompanyEdcConfiguration]);

  const { isLoading: isLoadingSaveCompanyEDCConfiguration, mutate: saveCompanyEDCConfiguration } =
    companyService.saveCompanyEDCConfiguration({
      onSuccess: () => {
        if (!getValues('pcfExchangeClientSecretKey')) {
          notify({
            severity: ToastSeverity.WARN,
            detail:
              'EDC Configuration saved sucessfully but Client Secret Key needs to be added for complete configuration.',
            life: 3000,
          });
        } else {
          notify({
            severity: ToastSeverity.SUCCESS,
            detail: 'EDC Configuration saved successfully',
            life: 3000,
          });
        }

        queryClient.invalidateQueries({ queryKey: ['listCompanies'] });
        queryClient.invalidateQueries({ queryKey: ['fetchCompanyEDCConfiguration', company.id] });
      },
      onError: () => {
        notify({
          severity: ToastSeverity.ERROR,
          detail: 'Cannot save EDC Configuration. Please try again.',
          life: 3000,
        });
      },
      onSettled: () => {
        onHideDialog();
      },
    });

  const onHideDialog = (): void => {
    setClientId('');
    setClientSecret('');
    setIsRevealClientSecretKey(false);
    setIsRevealPassword(false);
    setIsRevealSecret(false);
    reset();
    onHide();
  };

  const onToggleRevealSecret = (): void => {
    setIsRevealSecret((prevState) => !prevState);
  };

  const onToggleRevealPassword = (): void => {
    setIsRevealPassword((prevState) => !prevState);
  };

  const onToggleRevealClientSecretKey = (): void => {
    setIsRevealClientSecretKey((prevState) => !prevState);
  };

  const footerContent = (): JSX.Element => (
    <div className='border-top-1 border-gray-300 p-3'>
      <Button text className='text-gray-500' label='Cancel' onClick={onHideDialog} />
      <Button
        onClick={handleSubmit(onSubmit)}
        loading={isLoadingSaveCompanyEDCConfiguration}
        label='Save'
      />
    </div>
  );

  const isTestEdcConnectionButtonEnabled = Boolean(
    watchFields[0] && watchFields[1] && watchFields[2],
  );

  return (
    <Dialog
      {...restProps}
      onHide={onHideDialog}
      header={`${company?.company_name} EDC Configuration`}
      footer={footerContent}
      pt={{
        header: {
          className: 'bg-gray-200',
        },
        footer: {
          className: 'p-0',
        },
      }}
      draggable={false}
      blockScroll
    >
      <div className='mb-4'>
        <div className='flex justify-content-between mb-2'>
          <div className='flex gap-2'>
            <span>
              <b>Client ID:</b>
            </span>
            <span>
              {isLoadingFetchCompanyEDCConfiguration ? <Skeleton className='w-15rem' /> : clientId}
            </span>
          </div>
          <i
            className='pi pi-copy cursor-pointer'
            role='button'
            onClick={(): void => copyToClipboard({ value: clientId })}
          />
        </div>
        <div className='flex justify-content-between mb-2'>
          <div className='flex gap-2'>
            <span>
              <b>Client Secret:</b>
            </span>
            <ClientSecret
              isLoading={isLoadingFetchCompanyEDCConfiguration}
              clientSecret={clientSecret}
              isRevealSecret={isRevealSecret}
            />
          </div>
          <div className='flex gap-2'>
            <i
              className={classNames('pi cursor-pointer', {
                'pi-eye': isRevealSecret,
                'pi-eye-slash': !isRevealSecret,
              })}
              role='button'
              onClick={onToggleRevealSecret}
            />
            <i
              className='pi pi-copy cursor-pointer'
              role='button'
              onClick={(): void =>
                copyToClipboard({
                  value: clientSecret,
                })
              }
            />
          </div>
        </div>
      </div>
      <form>
        <p className='mb-4'>
          Please enter the information provided by the EDC provider to authenticate the PACIFIC
          backend to the customer EDC.
        </p>

        <div className='flex flex-column gap-2 mb-4'>
          <label htmlFor='edcBaseUrl'>
            EDC URL<sup>*</sup>
          </label>
          <CustomInputText
            name='edcBaseUrl'
            type='url'
            error={errors.edcBaseUrl?.message}
            register={register}
            isLoading={isLoadingFetchCompanyEDCConfiguration}
            className='w-full '
            style={{ height: '47px' }}
          />
        </div>

        <div className='flex gap-2 mb-4'>
          <div className='flex flex-column gap-2 w-full'>
            <label htmlFor='edcClientId'>
              Username<sup>*</sup>
            </label>
            <CustomInputText
              name='edcClientId'
              type='text'
              error={errors.edcClientId?.message}
              register={register}
              isLoading={isLoadingFetchCompanyEDCConfiguration}
              className='w-full'
              style={{ height: '47px' }}
              autoComplete='new-password' // To block browser's autofill
            />
          </div>

          <div className='flex flex-column gap-2 w-full'>
            <label htmlFor='edcClientSecret'>
              Password<sup>*</sup>
            </label>
            <span className='w-full'>
              <CustomInputText
                name='edcClientSecret'
                type={isRevealPassword ? 'text' : 'password'}
                error={errors.edcClientSecret?.message}
                register={register}
                isLoading={isLoadingFetchCompanyEDCConfiguration}
                className='w-full '
                style={{ height: '47px' }}
                autoComplete='new-password' // To block browser's autofill
                iconPosition='right'
                iconComponent={
                  <i
                    className={classNames('pi cursor-pointer', {
                      'pi-eye': isRevealPassword,
                      'pi-eye-slash': !isRevealPassword,
                    })}
                    role='button'
                    onClick={onToggleRevealPassword}
                  />
                }
              />
            </span>
          </div>
        </div>

        <div className='mb-4'>
          <TestEdcConnectionButton
            companyId={company.id}
            edcBaseUrl={watchFields[0]}
            edcClientId={watchFields[1]}
            edcClientSecret={watchFields[2]}
            disabled={!isTestEdcConnectionButtonEnabled}
          />
        </div>

        <div className='flex flex-column gap-2'>
          <div className='flex align-items-center gap-2'>
            <label htmlFor='pcfExchangeClientSecretKey'>Client Secret Key</label>
            <i
              className='pi pi-question-circle cursor-pointer custom-target-icon text-gray-500'
              role='button'
              data-pr-tooltip="Client Secret Key is used to protect your Client Secret on the EDC provider's side"
              data-pr-position='right'
              // data-pr-at='right+5 top'
              // data-pr-my='left center-2'
            />
            <Tooltip target='.custom-target-icon' />
          </div>
          <div>
            <CustomInputText
              name='pcfExchangeClientSecretKey'
              type={isRevealClientSecretKey ? 'text' : 'password'}
              register={register}
              isLoading={isLoadingFetchCompanyEDCConfiguration}
              className='w-full '
              style={{ height: '47px' }}
              iconPosition='right'
              iconComponent={
                <i
                  className={classNames('pi cursor-pointer', {
                    'pi-eye': isRevealClientSecretKey,
                    'pi-eye-slash': !isRevealClientSecretKey,
                  })}
                  role='button'
                  onClick={onToggleRevealClientSecretKey}
                />
              }
            />
          </div>
        </div>
      </form>
    </Dialog>
  );
}

export default ConfigureEDCConnectionDialog;
