import useNotificationContext from '@hooks/useNotificationContext';
import CompanyService from '@services/CompanyService';
import { ToastSeverity } from '@shared/enums/ToastSeverity';
import { ICompany, LicenseType, mapLicenseTypeToLabel } from '@shared/interfaces/ICompany';
import { useQueryClient } from '@tanstack/react-query';
import { Button } from 'primereact/button';
import { Dialog, DialogProps } from 'primereact/dialog';
import { Dropdown, DropdownChangeEvent } from 'primereact/dropdown';
import { useEffect, useState } from 'react';

import LicenseHistory from './LicenseHistory';

interface ChangeLicenseDialogProps extends DialogProps {
  company: ICompany;
}

const dropdownOptions = [
  { name: mapLicenseTypeToLabel(LicenseType.BASIC), value: LicenseType.BASIC },
  { name: mapLicenseTypeToLabel(LicenseType.PRO), value: LicenseType.PRO },
  { name: mapLicenseTypeToLabel(LicenseType.AUTOMOTIVE), value: LicenseType.AUTOMOTIVE },
];

const ChangeLicenseDialog = (props: ChangeLicenseDialogProps): JSX.Element => {
  const { company, onHide, ...restProps } = props;

  const [selectedLicense, setSelectedLicense] = useState<LicenseType>(company.license);

  useEffect(() => {
    setSelectedLicense(company.license);
  }, [company]);

  const { notify } = useNotificationContext();

  const queryClient = useQueryClient();
  const companyService = new CompanyService();

  const { isLoading: isLoadingUpdateLicense, mutate: updateLicense } = companyService.updateLicense(
    {
      onSuccess: () => {
        onHideDialog();
        queryClient.invalidateQueries({ queryKey: ['listCompanies'] });
        queryClient.invalidateQueries({ queryKey: ['fetchLicenseHistory', company.id] });
      },
      onError: () => {
        notify({
          severity: ToastSeverity.ERROR,
          detail:
            "We couldn't assign the license. Please try again or contact support if the issue persists", // eslint-disable-line quotes
          life: 3000,
        });
      },
    },
  );

  const onHideDialog = (): void => {
    onHide();
  };

  const onClickAssign = (): void => {
    if (selectedLicense === company.license) return;

    updateLicense({ companyId: company.id, license: selectedLicense });
  };

  const footerContent = (): JSX.Element => (
    <div className='border-top-1 border-gray-300 p-3'>
      <Button text className='text-gray-500' label='Cancel' onClick={onHideDialog} />
      <Button
        onClick={onClickAssign}
        label='Assign'
        disabled={isLoadingUpdateLicense || selectedLicense === company.license}
        loading={isLoadingUpdateLicense}
      />
    </div>
  );

  const onSelectLicense = (e: DropdownChangeEvent): void => {
    setSelectedLicense(e.value as LicenseType);
  };

  return (
    <Dialog
      onHide={onHideDialog}
      header={'License Assignment'}
      footer={footerContent}
      pt={{
        header: {
          className: 'bg-gray-200',
        },
        footer: {
          className: 'p-0',
        },
      }}
      draggable={false}
      blockScroll
      {...restProps}
    >
      <div className='my-4'>
        <div className='flex flex-column gap-2 mb-6'>
          <span>Assign license</span>
          <Dropdown
            value={selectedLicense}
            onChange={onSelectLicense}
            options={dropdownOptions}
            optionLabel='name'
            optionValue='value'
            className='w-6'
          />
        </div>

        <LicenseHistory companyId={company.id} />
      </div>
    </Dialog>
  );
};

export default ChangeLicenseDialog;
