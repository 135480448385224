import CompanyService from '@services/CompanyService';
import { parseDateWithTime } from '@shared/helpers/parse-date';
import { mapLicenseTypeToLabel } from '@shared/interfaces/ICompany';
import { Skeleton } from 'primereact/skeleton';

type LicenseHistoryProps = {
  companyId: string;
};

const LicenseHistory = ({ companyId }: LicenseHistoryProps): JSX.Element | null => {
  if (!companyId) return null;

  const companyService = new CompanyService();

  // RQ to fetch license history
  const { data: licenseHistory, isLoading } = companyService.fetchLicenseHistory(companyId, {
    enabled: Boolean(companyId),
  });

  return (
    <div className='flex flex-column gap-2'>
      License history
      {isLoading ? (
        <div className='flex flex-column gap-2 bg-gray-100 p-3 border-1 border-gray-200 border-round'>
          <Skeleton width='40%' />
          <Skeleton width='80%' />
          <Skeleton width='60%' />
        </div>
      ) : (
        <div className='flex flex-column gap-2 ml-4'>
          {licenseHistory?.map((item) => (
            <div key={item.id} className='flex gap-1'>
              {item.previous_license ? (
                <div className='flex gap-1'>
                  <span>{mapLicenseTypeToLabel(item.previous_license)}</span>
                  <span>{'→'}</span>
                  <span>{mapLicenseTypeToLabel(item.license)}</span>
                </div>
              ) : (
                <span>{mapLicenseTypeToLabel(item.license)}</span>
              )}
              <span>{'|'}</span>
              <span>{item.email}</span>
              <span>{'|'}</span>
              <span>{parseDateWithTime(new Date(item.date_created).toString())}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default LicenseHistory;
